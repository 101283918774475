import { AppError } from '@whispli/error'
import type { Locale } from '@whispli/i18n/types'
import type { LocaleCode } from '@whispli/i18n/types'
import type { Adapter } from '@whispli/ui/i18n/adapters/types'
import __debug__ from '@whispli/ui/utils/debug'

export { find } from '@whispli/ui/i18n/utils/find'

/** Call each adapter synchronously */
const setLocale = async (
  adapters: ReadonlyArray<Adapter>,
  locales: ReadonlyArray<Locale>,
  locale: LocaleCode,
): Promise<LocaleCode> => {
  let i = 0
  do {
    try {
      await adapters[i].set?.(locale, locales)
      i++
    } catch (err) {
      __debug__(err) // eslint-disable-line no-console
      break
    }
  } while (i < adapters.length)

  document.documentElement.setAttribute('lang', locale)

  return locale as LocaleCode
}

/** Fetch all locales concurrently */
export const LoadFactory = (
  adapters: ReadonlyArray<Adapter>,
  locales: ReadonlyArray<Locale>,
  loaded: Set<LocaleCode>,
) => async (locale: LocaleCode): Promise<boolean> => {
  await Promise.all(
    adapters.map(
      async (adapter) => {
        try {
          await adapter.load?.(locale, locales)
        } catch (err) {
          const error = new AppError(
            `Unsupported locale: ${locale}`,
            undefined,
            undefined,
            err instanceof Error ? err : undefined,
          )
          __debug__(error, true)

          return false
        }
      },
    ),
  )

  loaded.add(locale)

  return true
}

/**
 * Loads locale bundles and sets locale per localizable dependency
 *
 * @see @whispli/ui/plugins/vue-i18n/index.ts
 */
export const ChangeLocaleFactory = (
  adapters: ReadonlyArray<Adapter>,
  locales: ReadonlyArray<Locale>,
  loaded: Set<LocaleCode>,
  /** True if application supports `locale` and the language file was loaded */
  hasLocaleText: ReturnType<typeof LoadFactory>,
  ...cb: ((locale) => Promise<void>)[]
) => async (locale: LocaleCode): Promise<LocaleCode | void> => {
  try {
    if (
      loaded.has(locale)
        || (await hasLocaleText(locale))
    ) {
      await setLocale(adapters, locales, locale)
      await Promise.all(cb.map((cb) => cb(locale)))

      return locale
    }
  } catch (err) {
    __debug__(err)
  }
}
