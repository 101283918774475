export const BASE_URL = window.location.origin + '/api'

export enum BLOCK_TYPE {
  LOGO = 1,
  TITLE,
  DESCRIPTION,
  IMAGE,
  CHANNEL_LINK,
  TEXT,
  DEFAULT
}
