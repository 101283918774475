import { LOCALES, VUETIFY_LOCALE_MAP } from '@whispli/i18n/constants'
import type { Locale } from '@whispli/i18n/types'
import type { LocaleCode } from '@whispli/i18n/types'
import { find } from '@whispli/ui/i18n/utils'
import type { Adapter } from './types'
import { useVuetify } from '@whispli/ui/composables/useVuetify'
import { useI18n } from '@whispli/i18n/composables/useI18n'
import __debug__ from '@whispli/ui/utils/debug'

export const load = async (locale: LocaleCode): Promise<void> => {
  try {
    const { i18n } = useI18n()
    const normalizedLocale = VUETIFY_LOCALE_MAP[locale] || locale
    /**
     * @note Assign Vuetify locales to Vuetify supported locale.
     * We cannot guarantee that $vuetify.lang.current will be fully compatible with all LocaleCode members.
     */
    i18n.mergeLocaleMessage(normalizedLocale, { $vuetify: (await importLocale(normalizedLocale))?.default ?? {} })
  } catch (err) {
    __debug__(err, false)
  }
}

export const set = (
  locale: LocaleCode,
  locales: ReadonlyArray<Locale> = LOCALES,
): void => {
  try {
    const vuetify = useVuetify()
    const normalizedLocale = VUETIFY_LOCALE_MAP[locale] || locale
    vuetify.rtl = !!find(locales, locale)?.rtl
    ;(vuetify.lang ||= {}).current = normalizedLocale
  } catch (err) {
    __debug__(err, false)
  }
}

const importLocale = (locale: keyof typeof VUETIFY_LOCALE_MAP | LocaleCode) => {
  switch (locale) {
    case 'af':
      return import('vuetify/lib/locale/af')
    case 'ar':
      return import('vuetify/lib/locale/ar')
    case 'az':
      return import('vuetify/lib/locale/az')
    case 'bg':
      return import('vuetify/lib/locale/bg')
    case 'ca':
      return import('vuetify/lib/locale/ca')
    case 'ckb':
      return import('vuetify/lib/locale/ckb')
    case 'cs':
      return import('vuetify/lib/locale/cs')
    case 'de':
      return import('vuetify/lib/locale/de')
    case 'el':
      return import('vuetify/lib/locale/el')
    case 'en':
      return import('vuetify/lib/locale/en')
    case 'es':
      return import('vuetify/lib/locale/es')
    case 'et':
      return import('vuetify/lib/locale/et')
    case 'fa':
      return import('vuetify/lib/locale/fa')
    case 'fi':
      return import('vuetify/lib/locale/fi')
    case 'fr':
      return import('vuetify/lib/locale/fr')
    case 'he':
      return import('vuetify/lib/locale/he')
    case 'hr':
      return import('vuetify/lib/locale/hr')
    case 'hu':
      return import('vuetify/lib/locale/hu')
    case 'id':
      return import('vuetify/lib/locale/id')
    case 'it':
      return import('vuetify/lib/locale/it')
    case 'ja':
      return import('vuetify/lib/locale/ja')
    case 'ko':
      return import('vuetify/lib/locale/ko')
    case 'lt':
      return import('vuetify/lib/locale/lt')
    case 'lv':
      return import('vuetify/lib/locale/lv')
    case 'nl':
      return import('vuetify/lib/locale/nl')
    case 'no':
      return import('vuetify/lib/locale/no')
    case 'pl':
      return import('vuetify/lib/locale/pl')
    case 'pt':
      return import('vuetify/lib/locale/pt')
    case 'ro':
      return import('vuetify/lib/locale/ro')
    case 'ru':
      return import('vuetify/lib/locale/ru')
    case 'sk':
      return import('vuetify/lib/locale/sk')
    case 'sl':
      return import('vuetify/lib/locale/sl')
    case 'sr-Cyrl':
      return import('vuetify/lib/locale/sr-Cyrl')
    case 'sr-Latn':
      return import('vuetify/lib/locale/sr-Latn')
    case 'sv':
      return import('vuetify/lib/locale/sv')
    case 'th':
      return import('vuetify/lib/locale/th')
    case 'tr':
      return import('vuetify/lib/locale/tr')
    case 'uk':
      return import('vuetify/lib/locale/uk')
    case 'vi':
      return import('vuetify/lib/locale/vi')
    case 'zh-Hans':
      return import('vuetify/lib/locale/zh-Hans')
    case 'zh-Hant':
      return import('vuetify/lib/locale/zh-Hant')
    default:
      return Promise.resolve({ default: {} })
  }
}

export const VuetifyAdapter: Adapter = { load, set }
