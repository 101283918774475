import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __LandingPageBlockType extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly name!: string;
  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => Object)
  private readonly created_at!: string | null;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly updated_at!: string | null;

  public get updatedAt() {
    return this.updated_at
  }
}
export type LandingPageBlockType = Omit<__LandingPageBlockType, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'created_at' | 'updated_at'>
export const LandingPageBlockType = __LandingPageBlockType as any as Constructor<LandingPageBlockType>
