import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import { LandingPageBlockType } from './../../overrides/model/LandingPageBlockType.js';
import { LandingPage } from './../../overrides/model/LandingPage.js';
import { ChannelSimple } from './../../overrides/model/ChannelSimple.js';
import { LocaleTranslation } from './../../overrides/model/LocaleTranslation.js';
import { MediaSimple } from './../../overrides/model/MediaSimple.js';
import type { Constructor } from '@whispli/types';

export class __LandingPageBlock extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly title!: string;
  @Expose()
  @Type(() => String)
  public readonly description!: string;
  @Expose()
  @Type(() => String)
  public readonly locale!: string;
  /**
   * @deprecated For backwards-compatibility only. Use isVisible
   */
  @Expose()
  @Type(() => Object)
  private readonly is_visible!: boolean | null;

  public get isVisible() {
    return this.is_visible
  }

  @Expose()
  @Type(() => Number)
  public readonly order!: number;
  /**
   * @deprecated For backwards-compatibility only. Use mobileDeepLink
   */
  @Expose()
  @Type(() => Object)
  private readonly mobile_deep_link!: string | null;

  public get mobileDeepLink() {
    return this.mobile_deep_link
  }

  /**
   * @deprecated For backwards-compatibility only. Use landingPageBlockTypeId
   */
  @Expose()
  @Type(() => String)
  private readonly landing_page_block_type_id!: string;

  public get landingPageBlockTypeId() {
    return this.landing_page_block_type_id
  }

  /**
   * @deprecated For backwards-compatibility only. Use landingPageBlockType
   */
  @Type(() => LandingPageBlockType)
  @Expose()
  private readonly landing_page_block_type!: LandingPageBlockType | null;

  public get landingPageBlockType() {
    return this.landing_page_block_type
  }

  /**
   * @deprecated For backwards-compatibility only. Use landingPageId
   */
  @Expose()
  @Type(() => String)
  private readonly landing_page_id!: string;

  public get landingPageId() {
    return this.landing_page_id
  }

  /**
   * @deprecated For backwards-compatibility only. Use landingPage
   */
  @Type(() => LandingPage)
  @Expose()
  private readonly landing_page!: LandingPage | null;

  public get landingPage() {
    return this.landing_page
  }

  /**
   * @deprecated For backwards-compatibility only. Use channelId
   */
  @Expose()
  @Type(() => Object)
  private readonly channel_id!: string | null;

  public get channelId() {
    return this.channel_id
  }

  @Type(() => ChannelSimple)
  @Expose()
  public readonly channel!: ChannelSimple | null;
  @Type(() => LocaleTranslation)
  @Expose()
  public readonly translations!: (LocaleTranslation)[];
  @Type(() => MediaSimple)
  @Expose()
  public readonly media!: (MediaSimple)[];
  /**
   * @deprecated For backwards-compatibility only. Use createdAt
   */
  @Expose()
  @Type(() => Object)
  private readonly created_at!: string | null;

  public get createdAt() {
    return this.created_at
  }

  /**
   * @deprecated For backwards-compatibility only. Use updatedAt
   */
  @Expose()
  @Type(() => Object)
  private readonly updated_at!: string | null;

  public get updatedAt() {
    return this.updated_at
  }
}
export type LandingPageBlock = Omit<__LandingPageBlock, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual' | 'is_visible' | 'mobile_deep_link' | 'landing_page_block_type_id' | 'landing_page_block_type' | 'landing_page_id' | 'landing_page' | 'channel_id' | 'created_at' | 'updated_at'>
export const LandingPageBlock = __LandingPageBlock as any as Constructor<LandingPageBlock>
