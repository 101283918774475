import type { LocaleCode } from '@whispli/i18n/types'
import type { Adapter } from './types'
import __debug__ from '@whispli/ui/utils/debug'

/**
 * @note DO NOT use `router.replace` to modify query. `setInitialLocale` (main.ts) will trigger a second navigation.
 * The locale will be present in `$route.query` after `history.replaceState`.
 */
export const set = (locale: LocaleCode | string): boolean | void => {
  try {
    const url = new URL(window.location.href)

    if (locale === url.searchParams.get('locale')) {
      return true
    }

    url.searchParams.set('locale', locale)

    // Replace locale in query string; on refresh the previous locale will be loaded
    history.replaceState(null, '', `${url.pathname}${url.search}${url.hash || ''}`)
  } catch (err) {
    __debug__(err, false)
  }
}

export const VueRouterAdapter: Adapter = { set }
