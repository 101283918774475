import { useImportLocale } from '@whispli/i18n/composables/useImportLocale'
import __debug__ from '@whispli/ui/utils/debug'
import { modules } from '@whispli/ui/i18n'

export const load = async (locale: string): Promise<void> => {
  try {
    const { load } = useImportLocale(modules)
    await load(locale)
  } catch (err) {
    __debug__(err, true, true, undefined, { data: { locale } })
  }
}

export const UiAdapter = { load }

