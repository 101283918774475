import type { LocaleCode } from '@whispli/i18n/types'
import { LOCALE_CODES, LOCALES } from './locales'
import { VueRouterAdapter } from '@whispli/ui/i18n/adapters/vue-router'
import { VueI18nAdapter } from '@whispli/ui/i18n/adapters/vue-i18n'
import { VuetifyAdapter } from '@whispli/ui/i18n/adapters/vuetify'
import { UiAdapter } from '@whispli/ui/i18n/adapters'
import {
  ChangeLocaleFactory,
  find as _find,
  LoadFactory,
} from '@whispli/ui/i18n/utils'
import memoizeWith from 'ramda/es/memoizeWith'
import toString from 'ramda/es/toString'
import { getInitialLocale } from '@whispli/i18n/utils/get-initial-locale'
import { useI18n } from '@whispli/i18n/composables/useI18n'

/** Track previously loaded locales */
const LOADED_LOCALES = new Set<LocaleCode>([])

/** A list of third-party dependencies requiring or providing localization */
const ADAPTERS = Object.freeze([
  VueRouterAdapter,
  VueI18nAdapter,
  VuetifyAdapter,
  UiAdapter,
])

export const load = LoadFactory(ADAPTERS, LOCALES, LOADED_LOCALES)

export const find = memoizeWith(toString, _find.bind(null, LOCALES))

export const changeLocale = ChangeLocaleFactory(ADAPTERS, LOCALES, LOADED_LOCALES, load)

export const setInitialLocale = (
  translationSettings: { locale: { code: LocaleCode }; isDefault: boolean }[]
): Promise<LocaleCode | void> => {
  const { i18n } = useI18n()
  let code = getInitialLocale(LOCALE_CODES) || i18n?.locale
  // If page does not support browser default locale
  if (!translationSettings.find((e) => e.locale.code === code)) {
    // Use the page's default locale
    code = translationSettings.find((e) => e.isDefault)?.locale?.code ?? code
  }

  return changeLocale(code)
}
