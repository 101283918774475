import { ResponseBody } from './../../overrides/model/ResponseBody.js';
import { Expose } from 'class-transformer';
import { Type } from 'class-transformer';
import type { Constructor } from '@whispli/types';

export class __LocaleTranslation extends ResponseBody {
  @Expose()
  @Type(() => String)
  public readonly id: string = '';
  @Expose()
  @Type(() => String)
  public readonly locale!: string;
  @Expose()
  @Type(() => Object)
  public readonly value!: string | null;
  @Expose()
  @Type(() => Object)
  public readonly description!: string | null;
}
export type LocaleTranslation = Omit<__LocaleTranslation, '__typename' | '__key' | '__locale' | '__omit' | 'toJSON' | 'isEqual'>
export const LocaleTranslation = __LocaleTranslation as any as Constructor<LocaleTranslation>
