import type { LocaleCode } from '@whispli/i18n/types'
import type { Adapter } from './types'
import { useI18n } from '@whispli/i18n/composables/useI18n'
import __debug__ from '@whispli/ui/utils/debug'
import { useImportLocale } from '@whispli/i18n/composables/useImportLocale'

const modules = import.meta.glob([ '/src/i18n/messages/*.json', '!/src/i18n/messages/*.source.*' ], { import: 'default' })

export const load = async (locale: LocaleCode | string) => {
  try {
    const { load } = useImportLocale(modules)
    await load(locale)
  } catch (err) {
    __debug__(err, true, true, undefined, { data: { locale } })
  }
}

export const set = (locale: LocaleCode | string): void => {
  try {
    const { i18n } = useI18n()

    i18n!.locale = locale
  } catch (err) {
    __debug__(err)
  }
}

export const VueI18nAdapter: Adapter = { load, set }
